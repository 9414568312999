<template>
<div>
    <div class="col-md-12">
        <div class="row" style="margin-top:20px">
            <div class="col-md-6">
                <h3 class="page-header">
                <i class="fa fa fa-mobile animated bounceInDown show-info"></i>
                    Update Register Mobile Number(R.M.N)
                </h3>
            </div>
            <div class="col-md-6 ">
                <div class="row">
                    <div class="col-md-10" >
                        <div class="row" style="padding-top:18px;justify-content:flex-end;">
                            <div  class="col-md-2" style="margin-right:25px;">             
                                <button @click="refreshall()" class="btn btn-primary" ><i class="fa fa-refresh"></i>  Refresh</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
    <div class="col-md-12">
        <div class="panel panel-cascade">
            <div class="info-box bg-warning text-white">
                <div class="info-icon bg-primary-dark" style="width:30% ;">
                    <h4>
                        <i class="fa fa-user"></i>
                        Searh Customer ID No.
                    </h4>
                </div>
            </div>
            <div class="panel-body">
                <table class="table">
                    <tbody>
                        <tr>
                            <td>Enter VSDIGI-ID : </td>
                            <td><input class="form-control form-cascade-control input-smal input-small" v-model="searchstring" type="text" style="background-color: rgb(255, 255, 255);"></td>
                            <td>
                                <button type="submit" @click="search()" class="btn bg-primary text-white btn-md">Search</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <div class="info-box  bg-sheme  text-white" v-if="customersdata!=null && customersdata.length > 0">
            <div class="info-icon bg-sheme-dark" style="padding:5px;">
                <h5><i class="fa fa fa-user" style="font-size:25px"></i>  Customer Details</h5>
            </div>
        </div>
        <div class="panel panel-cascade" v-if="customersdata!=null && customersdata.length > 0">
            <div class="panel-body">
                <div class="form-horizontal cascde-forms">
                    <table class="table table-sm table-hover table-inverse table-striped display">
                        <thead class="table-font">
                            <tr>
                                <th class="visible-lg">#</th>
                                <th class="visible-lg" >VSDIGI-ID</th>
                                <th class="visible-lg">Name</th>
                                <th>Contact</th>
                                <th>Address</th>
                                <!-- <th>Landmark</th> -->
                                <th>Registation_date</th>
                                <th>Introducer Details</th>
                                <th>Entry By</th>
                                <th>Credit Limit</th>
                                <th v-if="loginusercheck(120)">Set R.M.N</th>
                            </tr>
                        </thead>
                        <!-- <loan-emi-row v-for="(item,index) in edititem.emis" :index="index" v-bind:key="item.id" :emiid="item.id"></loan-emi-row> -->
                        <template v-if="customersdata!=null && customersdata.length > 0">
                            <tbody class="table-font">
                                <tr v-for="(item,index) in customersdata" v-bind:key="item.id">
                                    <td>{{index+1 }}</td>
                                    <td  class="visible-lg"><a href="#" >{{ item.generatedid }}</a></td>
                                    <td><a  href="#" >{{item.name}}</a></td> 
                                    <td><display-mobiles :mobiles="item.mobiles"></display-mobiles></td>
                                    <td><display-first-address :addresses="item.addressess"></display-first-address></td>
                                    <!-- <td v-if="item.addressess!=null">{{item.addressess.landmark}}</td> -->
                                    <td>{{  moment(item.registation_date).format("DD-MM-YYYY")}}</td>
                                    <td style="width:14%">
                                        <template v-if="item.referraluser!=null">
                                            {{ item.referraluser.generatedid }}<br>
                                            {{item.referraluser.name}}
                                            <display-mobiles :mobiles="item.referraluser.mobiles"></display-mobiles>
                                        </template>
                                    </td>
                                    <td ><div v-if="item.enteredby!=null">
                                        {{item.enteredby.name}}<br>
                                        {{moment(item.created_at).format('DD-MM-YYYY')}}<br>
                                        {{moment(item.created_at).format('hh:mm:ss')}}
                                        </div>
                                    </td>
                                    <!-- <td style="text-align:center"><div v-if="item.active==0"><span style="color:green"><i class='bx bxs-circle'></i></span></div>
                                        <div v-if="item.active==1"><span style="color:red"><i class='bx bxs-circle'></i></span></div>
                                    </td> -->
                                    <td>{{item.creditlimit}}</td>
                                    <td v-if="loginusercheck(120)">
                                        <button type="button" class="btn btn-xs"  @click="setmobilermn(item)">
                                            <div class="btn-danger advisor b-r" style="padding:1px 5px">R.M.N</div>
                                            <!-- <div class="b-r" style="background-color:red;color:white;padding:1px 5px" v-else>Remove </div> -->
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <set-r-m-n></set-r-m-n>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import Constants from '../../components/utilities/Constants.vue'
import DisplayMobiles from '../../components/mobile/DisplayMobiles.vue'
import DisplayFirstAddress from '../../components/address/DisplayFirstAddress.vue'
import moment from 'moment'
import SetRMN from '../customer/SetRMN.vue'
export default {
    mixins:[Constants],
    components :{ DisplayMobiles, DisplayFirstAddress, SetRMN },
    data(){
        return {
            searchstring:'',
            moment:moment,
            customersdata:[],
            paymentid:1,
        }
    },
    computed:{
        ...mapGetters([
            'edititem'
        ]),
    },
    methods:{
        search(){
            let param = {searchstring:this.searchstring}
            this.$http.post('api/searchallrecords',param)
            .then((response) => this.processResponse1(response.data))
            .catch((err) => {
                console.log('', err)
            });
        },
        processResponse1(data){
            this.customersdata=data.data
            if(this.customersdata.length > 0){
                this.$notify({
                    text:'Data found',
                    type:'success',
                    top:'100',
                    duration:'80000',
                    speed:'800',
                })
            }else{
                this.$notify({
                    text:'Data Not Found',
                    type:'error',
                    top:'100',
                    duration:'80000',
                    speed:'800',
                })
            }
        },
        refreshall(){
            this.searchstring=''
            this.customersdata=[]
            
        },
        refresh(){
            this.search();
        },
        setmobilermn(item){
            if(item.mobiles!=null && item.mobiles.length >0){
                this.$modal.show('setrmn')
                this.$store.commit('assignsetmobileno',item)
            }else{
                alert("No Mobile number Found to set RMN")
            }
        },
    }
}
</script>