<template>
    <div>
        <modal name="setrmn" :width="600" :height="600"  draggable=".window-header">
            <div class="col-md-12 pb-20 text-center">
                <h3 for="remark" class="window-header" style="border-bottom: 1px solid green;">Set Register Mobile Number(R.M.N)</h3>
            </div>
            <div class="col-md-12 flex-column" v-if="setmobileno!=null">
                <div class="col-md-8 flex-between-row">
                    <label for=""><b>Vsdigi Id:</b> </label>
                    <label for="">{{setmobileno.generatedid}}</label>
                </div>
                <div class="col-md-8 flex-between-row">
                    <label for=""><b>Customer Name:</b> </label>
                    <label for="">{{setmobileno.name}}</label>
                </div>
            </div>
            <hr>
            <div class="col-md-12 pb-20 flex-between-row">
                <label for=""><u style="font-size:20px ;">Mobiles</u></label>
                <button class="btn bg-danger text-white btn-sm chkcondition" @click="addbtn()">
                    <span >Add Mobile</span>
                </button>
            </div>
            <div class="col-md-12 flex-column">
                <div class="col-md-12 flex-between-row pb-20" v-for="(mobile,index) in displaymobiles()" :key="mobile.id">
                    <label>{{index+1}}.</label>
                    <label for="">{{mobile.mobile}}</label>
                    <button v-if="mobile.isactive==0" class="btn bg-primary text-white btn-sm chkcondition" @click="setno(mobile)">
                        <span >Set</span>
                    </button>
                    <span v-if="mobile.isactive==1" style="color:green">R.M.N.</span>
                    <button class="btn bg-primary text-white btn-sm chkcondition">
                        <span>Send OTP</span>
                    </button>
                    <button class="btn bg-success text-white btn-sm chkcondition" @click="edit(mobile)">
                        <span>Edit</span>
                    </button>
                </div>
                <div v-if="addopen" class="col-md-12 flex-between-row pb-20" >
                    <label for="">Mobile</label>
                    <input type="mobile" v-model="mobile" maxlength="10">
                    <button class="btn bg-danger text-white btn-sm chkcondition" @click="add()">
                        <span >Add</span>
                    </button>
                </div>
            </div>
            
        </modal>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            clickadvice:false,
            mobile:'',
            id:0,
            addopen:false,
        }
    },
    computed:{
        ...mapGetters(["setmobileno"])
    },
    mounted() {
        this.addopen=false
    },
    methods: {
        initialize(){
            this.id=0
            this.mobile=''
        },
        close(){
            this.$modal.hide("setrmn")
        },
        edit(mobile){
            this.addopen=!this.addopen
            this.id=mobile.id
            this.mobile=mobile.mobile
        },
        displaymobiles(){
            if(this.setmobileno.mobiles!=null){
                return this.setmobileno.mobiles
            }else{
                return ""
            }
        },
        setno(mobile){
            let param={mobileid:mobile.id,userid:mobile.userid}
            this.$http.post('api/setregister/mobileno',param)
                .then((response) => this.processSetResponse(response.data))
                .catch((err) => {console.log(err)});
        },
        processSetResponse(){
            // alert("Mobile No. Registered Successfully")
            this.$parent.refresh()
            this.$modal.hide("setrmn")
        },
        addbtn(){
            this.addopen=!this.addopen
            this.initialize()
        },
        add(){
            let param={id:this.id,userid:this.setmobileno.id,mobile:this.mobile}
            this.$http.post('api/mobileno/createupdate',param)
                .then((response) => this.processCreateResponse(response.data))
                .catch((err) => {console.log(err)});
        },
        processCreateResponse(){
            this.$parent.refresh()
            this.$modal.hide("setrmn")
            this.addopen=false
            this.initialize()
        },
       
    },
}
</script>